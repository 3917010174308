import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Check } from '../assets/checkicon.svg'


const CheckBoxWrap = styled.label`
    position:relative;
    display:flex;
`

const Inputwrapp = styled.input`
    position:absolute;
    z-index:0;
    opacity: 0;
    pointer-events: none;
    
`

const Checkwrap = styled.div`
    width:15px;
    height:15px;
    border:1px solid #ccc;
    outline:none;
    background-color:#fff;
    border-radius:3px;
    positon:relative;
    margin-top:3px;
    
`

const Checkicon = styled.div`
    position:absolute;
    top:3px;
`

const Terms = styled.div`
    font-size:16px;
    font-weight:400;
    line-height:24px;
    color:#12006B;
    width:100%;
    max-width:500px;
    padding-left:5px;

    @media (max-width: 1615px) {
        font-size: 14px;
         max-width:420px;
    }
    
    @media (max-width: 1440.98px) { 
        max-width:320px;
        font-size:13px;
    }
      

    @media (max-width: 1199.98px) { 
        max-width:500px;
        font-size:12px;
    }

    @media (max-width: 899.98px) {
        max-width:240px;
        font-size:12px;
    }

    @media (max-width: 599.98px) { 
        max-width:240px;
        font-size:12px;
    }
`

const CheckBox = ({ name, value, isChecked, setIsChecked, setData }) => {

    // const [isChecked, setIsChecked] = useState(false);

    const handleChange = (e) => {
        setData(
            (prev) => ({
                ...prev,
                [name]: !isChecked
            })
        )
        setIsChecked(!isChecked);
    }

    return (
        <Grid container justifyContent={'center'}>
            <Grid item>
                <CheckBoxWrap>
                    <Inputwrapp type="checkbox" checked={isChecked} required name={name} value={value} onChange={handleChange} />
                    <Checkwrap />
                    <Checkicon><Check style={{ display: isChecked ? 'block' : 'none' }} /></Checkicon>
                    <Terms>Ich habe die Teilnahme-, Nutzungs- und Datenschutzbedingungen gelesen und bin damit einverstanden.*</Terms>
                </CheckBoxWrap>
            </Grid>
        </Grid>
    )
}

export default CheckBox