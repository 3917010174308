import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
// import SectionBreaker from '../Components/SectionBreaker'
// import Container from '../Components/Container'
import Card from '../Components/Card'
import { ReactComponent as Ps5 } from '../assets/ps5.svg'
import { ReactComponent as Iphone } from '../assets/iphone.svg'
import { ReactComponent as Airpods } from '../assets/airpods.svg'
import { ReactComponent as Applewatch } from '../assets/applewatch.svg'
import useWindowDimensions from '../hooks/usewindowdimensions'
import ResCard from '../Components/ResCard'


const Title = styled.div`
    font-size:26px;
    font-weight:800;
    color:#000;
    line-height:42px;
    text-align:center;
    width:100%;
    max-width:700px;

    @media (max-width: 899.98px) {
        font-size:18px;
        line-height:27px;
    }
`

// const ViewLink = styled.span`
//     font-weight:700;
//     font-size:19px;
//     line-height:32px;
//     color:#12006B;
//     text-decoration: underline;
//     text-decoration-color: #12006B; 
//     text-underline-offset: 4px;
//     cursor:pointer;

//     @media (max-width: 899.98px) {
//         font-size:16px;
//         line-height:19.2px;
//     }
// `
const SectionPrizes = () => {

    const { width } = useWindowDimensions();

    return (
        <Grid container flexDirection={'column'}  rowGap={5} width={'fit-content'} paddingTop={ "70px"}>
            <Grid item xs={'auto'}>
                <Grid container justifyContent={'center'}>
                    <Title>Spannende Preise erwarten Sie!</Title>
                </Grid>

            </Grid>

            {width > 899.99 ? (
                <Grid container rowGap={1} columnSpacing={1}>

                    <Grid item xs={6} >
                        <Card
                            prize={<Ps5 />}
                            title={'Sony Playstation 5'}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <Card
                            prize={<Iphone />}
                            title={'Iphone 15 Pro Max'}
                        />
                    </Grid>

                    <Grid item xs={6} >
                        <Card
                            prize={<Airpods />}
                            title={'Air Pods'}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <Card
                            prize={<Applewatch />}
                            title={'Apple Watch'}
                        />
                    </Grid>
                </Grid>


            ) : (
                <Grid container rowGap={1}>
                    <Grid item xs={12}>
                        <Grid container justifyContent={'center'} rowGap={1} columnSpacing={1}>
                            <Grid item xs={6} >
                                <ResCard
                                    prize={<Ps5 />}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <ResCard
                                    prize={<Iphone  />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent={'center'} rowGap={1} columnSpacing={1} >
                            <Grid item xs={6} >
                                <ResCard
                                    prize={<Airpods />}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <ResCard
                                    prize={<Applewatch />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            )
            }
            {/* <Grid item xs={'auto'}>
                <Grid container justifyContent={'center'}>
                    <ViewLink>View more</ViewLink>
                </Grid>

            </Grid> */}
        </Grid >
    )
}

export default SectionPrizes