import React from 'react'
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../assets/logo.svg'
// import Menu from './Menu';
import useWindowDimensions from '../hooks/usewindowdimensions';

const Wrapper = styled.div`
    padding-inline:130px;
    background-color:#fff;
    
    @media (max-width: 899.98px) {
        padding-inline:90px;
    }

    @media (max-width: 599.98px) { 
        padding-inline:30px;
    }
`

const Navbar = () => {

    const { width } = useWindowDimensions();

    return (
        <Wrapper>
            <Grid container py={2} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                    <Logo style={{ width: "100%", height: "auto", maxWidth: width < 899.99 && "140px" }} />
                </Grid>
                {/* <Grid item style={{ cursor: "pointer" }}>
                    <Menu  />
                </Grid> */}
            </Grid>
        </Wrapper>
    )
}

export default Navbar