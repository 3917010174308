import React, { useState } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
    width:244px;
    padding:7px 10px;
    border-radius:4px;
    border:1px solid #CED4DA;
    display:flex;
    flex-direction:column;
    position:relative;
    height:48px;

    ${({ isFocused }) =>
        isFocused &&
        css`
          outline: 1px solid #8474B4;
        `}

        @media (max-width: 1600.99px) {
            width:200px;
        }

         @media (max-width: 1400.99px) {
            width:150px;
        }

        @media (max-width: 1200.99px) {
            width:244px;
        }

        @media (max-width: 599.99px) {
            width:250px;
        }
`

const Label = styled.label`
    font-weight:400;
    font-size: ${({ isFocused, unfilled }) => (isFocused || unfilled ? '13.6px' : '16px')};
    line-height:24px;
    color:#8474B4;
    
    position:absolute;
    top: ${({ isFocused, geburtsdatum, unfilled }) => (isFocused || unfilled ? geburtsdatum && '5px' : geburtsdatum ? '5px' : '18px')};
    transition: font-size 0.3s, top 0.3s;

    @media (max-width: 1615px) {
          font-size: ${({ isFocused, unfilled }) => (isFocused || unfilled ? '12.6px' : '14px')};
    }

    @media (max-width: 1440px) {
          font-size: ${({ isFocused, unfilled }) => (isFocused || unfilled ? '10.6px' : '12px')};
    }


    @media (max-width: 899.99px) {
        font-size: ${({ isFocused, geburtsdatum, unfilled }) => (isFocused || unfilled ? '12px' : geburtsdatum ? '12px' : '13.6px')};
    }

    @media (max-width: 599.99px) {
        font-size: ${({ isFocused, geburtsdatum, unfilled }) => (isFocused || unfilled ? '12px' : geburtsdatum ? '12px' : '13.6px')};
    }
`

const Input = styled.input`
    border:none;
    outline:none;
    position:absolute;
    top:30px;
    height:20px;
    width:90%;
    background-color:transparent;

    @media (max-width: 599.99px) {
        max-width:230px;
    }
`

const PhoneInput = ({ label, type, inputValue, setInputValue, geburtsdatum, name, onChange, value }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        if (onChange) {
            onChange(event);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        if (!inputValue.startsWith("+41")) {
            setInputValue("+41");
        }
        setIsFocused(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Backspace" && event.target.selectionStart === 0 && inputValue.slice(3) === "") {
            event.preventDefault();
        }
    };

    return (
        <Wrapper isFocused={isFocused}>
            <Label isFocused={isFocused} unfilled={!!inputValue} geburtsdatum={geburtsdatum}>{label}</Label>
            <Input
                type={type}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name={name}
                required
                pattern="^\+41(?:[\.\-\s]?(21|22|24|26|27|31|32|33|34|41|43|44|51|52|55|56|58|61|62|71|74|76|77|78|79|81|91))(?:[\.\-\s]?\d\d\d)(?:[\.\-\s]?\d\d){2}$"
                minLength={12}
                maxLength={12}
                value={inputValue}
                onKeyDown={handleKeyDown}
            ></Input>
        </Wrapper>
    );
}

export default PhoneInput