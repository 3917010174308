import { Box, Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Hug } from '../assets/hug.svg'
import useWindowDimensions from '../hooks/usewindowdimensions'

const Thanks = styled.div`
    text-align:center;
    color:#12006B;
    font-size:41px;
    font-weight:800;
    line-height:42px;

    @media (max-width: 1440px) { 
        font-size:25px;
        line-height:27px;
        max-width:400px;
    }

    @media (max-width: 1199.98px) { 
        font-size:25px;
        line-height:27px;
        max-width:400px;
    }

    @media (max-width: 899.98px) {
        font-size:19px;
        line-height:27px;
        max-width:280px;
    }
`

const Desc = styled.div`
    text-align:center;
    color:#12006B;
    font-size:14px;
    font-weight:500;
    line-height:17.64px;
    width:100%;
    max-width:650px;

    @media (max-width: 1199.98px) { 
        font-size:16px;
        line-height:17px;        
    }

    @media (max-width: 899.98px) {
        font-size:12px;
        line-height:15.12px;
    }

    @media (max-width: 599.98px) {
        font-size:10px;
        line-height:15.12px;
        max-width:270px;
    }
`

const Thanku = () => {

    const width = useWindowDimensions();

    return (
        <Box paddingInline={'30px'}>
            <Grid container justifyContent={'center'} rowGap={width > 899.99 ? 6 : 3}>
                <Grid item>
                    <Thanks>Herzlichen Glückwunsch!</Thanks>
                </Grid>
                <Grid item>
                    <Desc>Sie haben an unserem exklusiven Gewinnspiel teilgenommen! Der glückliche Gewinner wird von unserem Team über die von Ihnen in den vorherigen Schritten angegebene Telefonnummer kontaktiert</Desc>
                </Grid>
                <Grid item>
                    <Hug style={{ width: '100%', height: 'auto', maxWidth: width < 899.99 && "10px" }} />


                </Grid>
            </Grid>
        </Box>
    )
}

export default Thanku