import { Grid } from '@mui/material'
import React from 'react'
import SectionBreaker from './SectionBreaker'
import Container from './Container'
import styled from 'styled-components'


const Copyrights  = styled.div`
    color:#8B8B8B;
    font-size:16px;
    font-weight:400;
    line-height:24px;
    text-align:center;
    @media (max-width: 899.99px) { 
        font-size:12px;
        line-height:20px;
    }
`
const Footer = () => {
    return (
        <SectionBreaker>
            <Container>
                <Grid container justifyContent={'center'} borderTop={'1px solid #8B8B8B4D'} py={3}>
                    <Copyrights>Copyright © 2024 Gewinne Einfach GMBH | All Rights Reserved </Copyrights>
                </Grid>
            </Container>
        </SectionBreaker>

    )
}

export default Footer