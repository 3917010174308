import React from 'react'
import styled from 'styled-components'

const Contain = styled.div`
    padding-inline:100px;
    margin:auto;
    
    

    @media (max-width: 1300px) { 
     padding-inline:50px;
    }

    @media (max-width: 1199.98px) { 
      padding-inline:50px;
    }

    @media (max-width: 899.99px) { 
        padding-inline:30px;
    }

    @media (max-width: 599.99px) { 
        padding-inline:50px;
    }

`

const Container = ({ children, style }) => {
  return (
    <Contain style={{ ...style }}>{children}</Contain>
  )
}

export default Container