import React, { useRef, useState } from 'react'
// import SectionBreaker from '../Components/SectionBreaker'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import axios from 'axios'
import Form from '../Components/Form'
import Thanku from '../Components/Thanku'

const FormContainer = styled.form`
    width:100%;
    max-width:700px;
    background-color:#fff;
    box-shadow: 0px 4px 18px 0px #E0E0E0;
    padding:70px 50px;
    justify-content:center;
    align-items:center;
    border-radius:15px;

    @media (max-width: 1199.98px) { 
        max-width:703px;
    }

    @media (max-width: 899.98px) {
        padding:24px 20px;
        max-width:523px;
    }

    @media (max-width: 599.98px) {
        padding:24px 5px;
        max-width:320px;
    }
`

const SectionForm = () => {

    const StepsRef = useRef(null)

    const [formData, setformData] = useState({
        vorname: '',
        nachname: '',
        email: '',
        tel: '',
        plz: '',
        krankenkasse: '',
        ort: '',
        strasse: '',
        tailnahme: false
    });

    const [Step, setStep] = useState(1)


    const handleChange = (e) => {
        const { name, value } = e.target;

        setformData({
            ...formData,
            [name]: value,
        });
    };


    const [inputValue, setInputValue] = useState('');

    const [phoneinputValue, setPhoneInputValue] = useState("+41");

    const [isChecked, setIsChecked] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            vorname: formData.vorname,
            nachname: formData.nachname,
            email: formData.email,
            tel: phoneinputValue,
            plz: formData.plz,
            krankenkasse: formData.krankenkasse,
            tailnahme: formData.tailnahme,
            ort: formData.ort,
            strasse: formData.strasse
        };

        try {
            const response = await axios.post("https://email.kutiza.com/send-email-Gewinne", data);
            console.log('Response:', response.data);
            // setSuccessfulMessage('Erfolgreich gesendet!');
            setStep(2)
            // setTimeout(() => {
            //     setSuccessfulMessage('');
            // }, 5000);
            setformData({
                vorname: '',
                nachname: '',
                email: '',
                tel: '',
                plz: '',
                krankenkasse: '',
                ort: '',
                strasse: '',
                tailnahme: false
            });
            setInputValue('');
            setPhoneInputValue('+41');
            setIsChecked(false);
        } catch (error) {
            if (error.response) {
                console.error('Server Error:', error.response.data);
                console.error('Status Code:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error:', error.message);
            }
            console.error('Error config:', error.config);
        }
    };

    return (

        <Grid container>
            <FormContainer onSubmit={handleSubmit}>

                <div ref={StepsRef}></div>
                {
                    {
                        1: <Form formData={formData}
                            handleChange={handleChange}
                            setInputValue={setInputValue}
                            inputValue={inputValue}
                            phoneinputValue={phoneinputValue}
                            setPhoneInputValue={setPhoneInputValue}
                            isChecked={isChecked}
                            setformData={setformData}
                            setIsChecked={setIsChecked} />,
                        2: <Thanku />
                    }[Step]
                }
            </FormContainer>
        </Grid>

    )
}

export default SectionForm