import { Grid } from '@mui/material'
import React from 'react'
import PrimaryInput from './PrimaryInput'
import PrimarySelect from './PrimarySelect'
import PrimaryButton from './PrimaryButton'
import CheckBox from './CheckBox'
import PhoneInput from './PhoneInput'
import styled from 'styled-components'

const Title = styled.div`
    font-size:41px;
    font-weight:800;
    color:#12006B;
    line-height:42px;
    text-align:center;
    width:100%;
    max-width:700px;

    @media (max-width: 1440px) { 
        font-size:25px;
        line-height:27px;
        max-width:400px;
    }

    @media (max-width: 1199.98px) { 
        font-size:25px;
        line-height:27px;
        max-width:400px;
    }

    @media (max-width: 899.98px) {
        font-size:19px;
        line-height:27px;
        max-width:280px;
    }
`

const BlueText = styled.span`
    font-size:41px;
    font-weight:800;
    color:#4185F4;
    line-height:42px;

    @media (max-width: 1440px) { 
        font-size:25px;
        line-height:27px;
    }
     
    @media (max-width: 1199.98px) { 
        font-size:25px;
        line-height:27px;
    }

    @media (max-width: 899.98px) {
        font-size:19px;
        line-height:27px;
    }

`

const Desc = styled.div`
    font-size:14px;
    font-weight:500;
    color:#12006B;
    line-height:17.64px;
    text-align:center;
    width:100%;
    max-width:600px;

    @media (max-width: 1199.98px) { 
        font-size:16px;
        line-height:17px;
        
    }

    
    @media (max-width: 899.98px) {
        font-size:12px;
        line-height:15.12px;
      
        color:#000000;
    }

    @media (max-width: 599.98px) {
        font-size:10px;
        line-height:15.12px;
        max-width:270px;
        color:#000000;
    }
`

const Form = ({formData, handleChange,setInputValue, inputValue, phoneinputValue, setPhoneInputValue, isChecked, setformData, setIsChecked }) => {
    return (
        <Grid container rowGap={2}>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                    <Title>Gewinnspiele und Wettberbe von <BlueText>gewinne-einfach.ch</BlueText></Title>
                </Grid>

            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                    <Desc> Auf unserer Webseite www.gewinne-einfach.ch werden wöchentlich neue Gewinnspiele veröffentlicht. Nehmen Sie jetzt an einem der Gewinnspiele teil und sichern Sie sich die Chance auf einen der folgenden Preise.</Desc>
                </Grid>
            </Grid>
            <Grid item >
                <Grid container justifyContent={'center'} columnGap={2} py={3} rowGap={2}>

                    <Grid item xs={'auto'}>
                        <PrimaryInput
                            type={'text'}
                            label={'Vorname'}
                            name={'vorname'}
                            value={formData.vorname}
                            onChange={handleChange}
                            inputValue={inputValue}
                            setInputValue={setInputValue} />
                    </Grid>
                    <Grid item xs={'auto'}>
                        <PrimaryInput
                            type={'text'}
                            label={'Nachname'}
                            name={'nachname'}
                            value={formData.nachname}
                            onChange={handleChange}
                            inputValue={inputValue}
                            setInputValue={setInputValue} />
                    </Grid>
                    <Grid item xs={'auto'}>
                        <PrimaryInput
                            type={'email'}
                            label={'E-Mail-Addresse'}
                            name={'email'}
                            value={formData.email}
                            onChange={handleChange}
                            inputValue={inputValue}
                            setInputValue={setInputValue} />
                    </Grid>
                    <Grid item xs={'auto'}>
                        <PhoneInput
                            type={'tel'}
                            label={'Handy Nummer'}
                            name={'tel'}
                            value={formData.tel}
                            onChange={handleChange}
                            inputValue={phoneinputValue}
                            setInputValue={setPhoneInputValue} />
                    </Grid>
                    <Grid item xs={'auto'}>
                        <PrimaryInput
                            type={'text'}
                            label={'PLZ'}
                            name={'plz'}
                            value={formData.plz}
                            onChange={handleChange}
                            inputValue={inputValue}
                            setInputValue={setInputValue} />
                    </Grid>
                    <Grid item xs={'auto'}>
                        <PrimaryInput
                            type={'text'}
                            label={'Ort'}
                            name={'ort'}
                            value={formData.ort}
                            onChange={handleChange}
                            inputValue={inputValue}
                            setInputValue={setInputValue} />
                    </Grid>
                    <Grid item xs={'auto'}>
                        <PrimaryInput
                            type={'text'}
                            label={'Strasse'}
                            name={'strasse'}
                            value={formData.strasse}
                            onChange={handleChange}
                            inputValue={inputValue}
                            setInputValue={setInputValue} />
                    </Grid>
                    <Grid item xs={'auto'}>
                        <PrimarySelect
                            type={'text'}
                            label={'Krankenkasse'}
                            name={'krankenkasse'}
                            value={formData.krankenkasse}
                            onChange={handleChange}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckBox name={'tailnahme'} isChecked={isChecked} setIsChecked={setIsChecked} value={formData.tailnahme} setData={setformData} />
                    </Grid>

                </Grid>

            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                    <PrimaryButton type='submit' text={'Jetzt kostenlos teilnehmen'} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Form