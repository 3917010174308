import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const Title = styled.div`
    font-size:22px;
    font-weight:600;
    line-height:42px;
    color:#000;

     @media (max-width: 1440px) { 
        font-size:20px;
    }

    @media (max-width: 1199.98px) { 
        font-size:20px;
    }
`
const StyledGrid = styled(Grid)`
   svg {
    width: auto;
    height: 130px;
   } 
`
const Card = ({ prize, title }) => {
    return (
        <Grid container flexDirection={'column'} rowGap={4} justifyContent={'center'} px={'25px'} py={'35px'} backgroundColor={'#fff'} boxShadow={'0px 4px 18px 0px #E0E0E0'} border={'1px solid #E8E8E8'} borderRadius={'8px'}>
            <Grid item xs={12}>

                <StyledGrid container justifyContent={'center'} height={'100px'} alignItems={'center'} >
                    {prize}
                </StyledGrid>

            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                    <Title>{title}</Title>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Card