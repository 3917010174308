import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
    width:100%;
    max-width:750px;
    background-color:#4185F4;
    padding:16px 30px;
    color:#fff;
    border:none;
    border-radius:11px;
    font-size:16px;
    font-weight:600;
    line-height:27px;
    text-align:center;
    cursor:pointer;

    @media (max-width: 1199.98px) { 
      font-size:16px;
      max-width:400px;
    }

    @media (max-width: 899.98px) {
        font-size:14px;
        line-height:27px;
        max-width:370px;
        padding:10px 30px;
        border-radius:9px;
    }

    @media (max-width: 599.98px) {
      font-size:14px;
      line-height:27px;
      max-width:280px;
      padding:10px 30px;
      border-radius:9px;
  }
`
const PrimaryButton = ({ text, type }) => {
  return (
    <Button type={type}>{text}</Button>
  )
}

export default PrimaryButton