import React, { useState } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
    width:244px;
    padding:7px 10px;
    border-radius:4px;
    border:1px solid #CED4DA;
    display:flex;
    flex-direction:column;
    position:relative;
    height:48px;

    ${({ isFocused }) =>
        isFocused &&
        css`
          outline: 1px solid #8474B4;
        `}

         @media (max-width: 1600.99px) {
            width:200px;
        }

        @media (max-width: 1400.99px) {
            width:150px;
        }

         @media (max-width: 1200.99px) {
            width:244px;
        }

        @media (max-width: 599.99px) {
            width:250px;
        }
`

const Label = styled.label`
    font-weight:400;
    font-size: ${({ isFocused, unfilled }) => (isFocused || unfilled ? '13.6px' : '16px')};
    line-height:24px;
    color:#8474B4;
    
    position:absolute;
    top: ${({ isFocused, geburtsdatum, unfilled }) => (isFocused || unfilled ? geburtsdatum && '5px' : geburtsdatum ? '5px' : '18px')};
    transition: font-size 0.3s, top 0.3s;

    @media (max-width: 1615px) {
          font-size: ${({ isFocused, unfilled }) => (isFocused || unfilled ? '12.6px' : '14px')};
    }

    @media (max-width: 1440px) {
          font-size: ${({ isFocused, unfilled }) => (isFocused || unfilled ? '10.6px' : '12px')};
    }


    @media (max-width: 899.99px) {
        font-size: ${({ isFocused, geburtsdatum, unfilled }) => (isFocused || unfilled ? '12px' : geburtsdatum ? '12px' : '13.6px')};
    }

    @media (max-width: 599.99px) {
        font-size: ${({ isFocused, geburtsdatum, unfilled }) => (isFocused || unfilled ? '12px' : geburtsdatum ? '12px' : '13.6px')};
    }
`

const Input = styled.select`
    border:none;
    outline:none;
    position:absolute;
    top:30px;
    height:20px;
    width:90%;
    background-color:transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color:#000;

    @media (max-width: 599.99px) {
        max-width:230px;
    }
`
const PrimaryInput = ({ label, type, inputValue, setInputValue, geburtsdatum, name, onChange, value }) => {

    const [isFocused, setIsFocused] = useState(false);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        if (onChange) {
            onChange(event);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
            <Wrapper isFocused={isFocused} >
                <Label isFocused={isFocused} unfilled={!!inputValue} geburtsdatum={geburtsdatum}>{label}</Label>
                <Input
                    type={type}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    name={name}
                    required
                    value={value}
                >
                    <option value="" disabled></option>
                    <option value="CSS">CSS</option>
                    <option value="Aquilana">Aquilana</option>
                    <option value="SUPRA">SUPRA</option>
                    <option value="Sumiswalder Krankenkasse">Sumiswalder Krankenkasse</option>
                    <option value="CONCORDIA">CONCORDIA</option>
                    <option value="Atupri">Atupri</option>
                    <option value="Avenir">Avenir</option>
                    <option value="KPT">KPT</option>
                    <option value="ÖKK">ÖKK</option>
                    <option value="Vivao Sympany">Vivao Sympany</option>
                    <option value="EasySana">EasySana</option>
                    <option value="KLuG">KLuG</option>
                    <option value="EGK">EGK</option>
                    <option value="SLKK">SLKK</option>
                    <option value="vita surselva">vita surselva</option>
                    <option value="SWICA">SWICA</option>
                    <option value="GALENOS">GALENOS</option>
                    <option value="rhenusana">rhenusana</option>
                    <option value="Mutuel Assurance (Groupe Mutuel)">Mutuel Assurance (Groupe Mutuel)</option>
                    <option value="Sanitas">Sanitas</option>
                    <option value="Philos">Philos</option>
                    <option value="Assura-Basis">Assura-Basis</option>
                    <option value="Visana AG">Visana AG</option>
                    <option value="Agrisano">Agrisano</option>
                    <option value="Helsana">Helsana</option>
                    <option value="sana24">sana24</option>
                    <option value="vivacare">vivacare</option>
                </Input>
            </Wrapper>
    )
}

export default PrimaryInput