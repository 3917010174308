import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const StyledGrid = styled(Grid)`
   svg {
    width: auto;
    height: 100px;
   } 
`

const ResCard = ({ prize }) => {
    return (
        <StyledGrid container justifyContent={'center'} height={'150px'} alignItems={'center'} padding={'18px'} backgroundColor={'#fff'} border={'1px solid #E8E8E8'} borderRadius={'8px'}>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                    {prize}
                </Grid>

            </Grid>
        </StyledGrid>
    )
}

export default ResCard