import React from 'react'
import SectionForm from '../Sections/SectionForm'
import SectionPrizes from '../Sections/SectionPrizes'
import { Grid } from '@mui/material'
import SectionBreaker from '../Components/SectionBreaker'
import Container from '../Components/Container'
import useWindowDimensions from '../hooks/usewindowdimensions'

const Home = () => {

    const { width } = useWindowDimensions();
    return (
        <SectionBreaker>
            <Container>
                <Grid container justifyContent={'center'} alignItems={'start'} columnSpacing={7} >
                    <Grid item xs={'auto'} lg={6}>
                        <SectionForm />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid container justifyContent={'center'} paddingInline={width < 1200 && width > 899 && '100px'}>
                            <SectionPrizes />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </SectionBreaker>
    )
}

export default Home